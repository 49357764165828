<template>
  <div>
    <div class="content">
      <div class="all">

    <!-- 字典配置 -->
    <div class="contain">
<el-table
      :data="tableData"
      style="width: 100%; margin:0 0 10px 0"
      class="table-no-search"
      row-key="id"
      border
      :default-expand-all="false"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      stripe
      height="calc(100vh - 250px)"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'left',
        background: '#f8f8f9',
      }"
    >
      <el-table-column
        prop="name"
        label="字典名称"
        align="left"
      ></el-table-column>
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column
        prop="sorting"
        label="字典排序"
      >
        <template slot-scope="{ row }">
          <el-input v-model.number="row.sorting" 
          placeholder="请输入排序"
          type="number"
          @input="row.sorting = row.sorting>9999  ? 9999 : (( row.sorting< 0) ? parseInt(Math.abs(row.sorting)): parseInt(row.sorting))"
          @blur="sortNumber(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
      ></el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            v-if="row.parentId != 0"
            @click="updataDIC(row)"
            size="small"
            >编辑</el-button
          >

          <el-button
            type="primary"
            v-if="row.code == '1000240001' || row.code == '1000240002' || row.code == '1000240003' || row.code == '1000230001' || row.code == '1000230002' || row.code == '1000230004' || row.code == '100001' || row.code == '300034'|| row.code == '100032' || row.code == '100019'"
            @click="addDIC(row)"
            size="small"
            >新增子字典</el-button
          >
          <!-- <el-button
            type="danger"
            v-if="row.isDelete != 1 && row.parentId != 0"
            @click="del(row)"
            size="small"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog
      :title="updatasonStatus == 0 ? '新增字典信息' : '修改字典信息'"
      :visible.sync="sondialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnsonF('sonform')"
    >
      <el-form ref="sonform" :model="sonform" label-width="110px" :rules="rule">
        <el-form-item label="字典名称：" prop="name">
          <el-input v-model="sonform.name"></el-input>
        </el-form-item>
        <el-form-item label="字典排序：" prop="sorting">
          <el-input v-model="sonform.sorting" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sondialogVisible = false">取 消</el-button>
        <el-button
          v-if="updatasonStatus == 0"
          type="primary"
          @click="submitsonfrom('sonform')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="updatasubmitsonfrom('sonform')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
  </div>
  </div>
</template>

<script>
import {
  dicpage,
  dicsave,
  dicupdata,
  updateSortNum,
} from "../../RequestPort/Login/LoginRequest";
import { dicdelete } from "../../RequestPort/dict/cycle";
export default {
  name: "Dict",
  data() {
    return {
      pageParams: {
        platform: 5,
      },
      sondialogVisible: false,
      tableData: [{}],
      sonform: {
        platform: 5,
        name: "",
        sorting: "0",
        code: "",
        description: "",
      },
      updatasonStatus: 0,
      rule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sorting: [{ required: true, message: "请输入排序", trigger: "blur" }],
        code: [{ required: true, message: "请输入编码", trigger: "blur" }],
        englishName: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
        ],
        value: [{ required: true, message: "请输入值", trigger: "blur" }],
      },
      options: [],
      value: "",
    };
  },
  mounted() {
    this.loadlist(this.pageParams);
  },
  methods: {
    /**
     * 获取字典数据
     */
    loadlist(obj) {
      dicpage(obj).then((res) => {
        this.tableData = res.data;
      });
    },
    /**
     * 排序
     */
    sortNumber(row) {
      console.log(row);
      if (!row.sorting) {
        row.sorting = 0;
      }
      let data = {
        id: row.id,
        sortNum: row.sorting,
        table: "gas_zhonglian_common.gas_dict",
      };
      updateSortNum(data).then((e) => {
        if (e.code === 200) {
          this.$message.success("修改排序成功");
          this.loadlist(this.pageParams);
        }
      });
    },
    recet() {
      this.pageParams.name = "";
      this.loadlist(this.pageParams);
    },
    clearnsonF(sonform) {
      (this.sonform = {
        platform: 5,
        name: "",
        sorting: "0",
        code: "",
      }),
        this.$refs[sonform].resetFields();
    },
    search() {
      this.pageParams.current = 1;
      this.loadlist(this.pageParams);
    },
    updataDIC(row) {
      this.updatasonStatus = 1;
      this.sonform = JSON.parse(JSON.stringify(row));
      this.sondialogVisible = !this.sondialogVisible;
    },
    addDIC(item) {
      this.sonform.pid = item.id;
      this.updatasonStatus = 0;
      this.sondialogVisible = !this.sondialogVisible;
    },
    submitsonfrom(sonform) {
      this.$refs[sonform].validate((valid) => {
        if (!valid) return false;
        console.log(this.sonform);
        dicsave(this.sonform).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.sondialogVisible = !this.sondialogVisible;
            this.loadlist(this.pageParams);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    updatasubmitsonfrom(sonform) {
      this.$refs[sonform].validate((valid) => {
        if (!valid) return false;
        this.sonform.englishName = null;
        dicupdata(this.sonform).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.sondialogVisible = !this.sondialogVisible;
            this.loadlist(this.pageParams);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    del(row) {
      this.$confirm(`是否删除字典---[ ${row.name} ]?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        dicdelete({ id: row.id }).then((e) => {
          if (e.code === 200) {
            this.$message.success(e.msg);
            this.loadlist(this.pageParams);
          } else {
            this.$message.error(e.msg);
          }
        });
      });
    },
  },
};
</script>

<style scoped>

.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
</style>
